<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="准备贡菜供饭仪式"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 9 }"
        :wrapper-col="{ span: 13 }"
        @submit="handleSubmit"
      >
        <span style="margin-left:50px;margin-top:50px;">请确认并维护以下信息。</span>
        <a-form-item label="仪式时间">
          <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            :allow-clear="false"
            v-decorator="['ceremony_time', {
              initialValue: defaultCeremonyTime
            }]"
          />
        </a-form-item>
        <a-form-item label="准备贡菜供饭仪式通知">
          <a-checkbox
            v-decorator="['notice', {
              initialValue: 'checked',
              valuePropName: 'checked'
            }]"
            @change="changeNotice"
          />
        </a-form-item>
        <a-form-item label="提醒内容" v-if="noticeSend === true">
          <a-textarea
            v-decorator="['content', {
              normalize: this.$lodash.trim,
              initialValue: defaultContent,
              rules: [
                { max: 100, message: '最多100个字符' },
                { required: true, message: '请输入提醒内容' }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="发送目标" v-if="noticeSend === true">
          <span>小程序用户</span>
          <a-select
            mode="multiple"
            placeholder="请选择工作人员"
            @load="loadingNoticeUserOptions"
            allow-clear
            v-decorator="['notice_user_ids']"
          >
            <a-select-option
              v-for="user in noticeUserOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="准备贡菜供饭仪式任务">
          <a-checkbox
            v-decorator="['task', {
              initialValue: 'checked',
              valuePropName: 'checked'
            }]"
            @change="changeTask"
          />
        </a-form-item>
        <a-form-item label="贡菜供饭仪式负责人" v-if="taskSend === true">
          <a-select
            v-decorator="['user_id', {
              initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,
              rules: [
                { required: true, message: '请选择贡菜供饭仪式负责人' },
              ]
            }]"
            placeholder="请选择贡菜供饭仪式负责人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话" v-if="taskSend === true">
          <a-input
            :disabled="true"
            v-decorator="['phone_number', {
              initialValue: defaultUser.phone_number,
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { prepareProvideFoodCeremony } from '@/api/order'
import { findFuneralHomeOptions, findTenantTaskUserOptions, findUser } from '@/api/user'
import { formatDateTimeMin } from '@/utils/time'

export default {
  name: 'PrepareProvideFoodCeremony',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    hallName: {
      type: String,
      required: true
    },
    deathName: {
      type: String,
      required: true
    },
    funeralHomeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'prepare_provide_food_ceremony' }),
      submitting: false,
      noticeUserOptions: [],
      userOptions: [],
      defaultUser: {},
      loadingUserOptions: false,
      loadingNoticeUserOptions: false,
      data: {},
      noticeSend: true,
      taskSend: true,
      defaultContent: '',
      defaultCeremonyTime: null,
      notice_user_ids: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    const startDate = this.$moment(this.$moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm')
    this.fetchUserOptions()
    this.fetchNoticeUserOptions()
    this.defaultContent = this.hallName + '(逝者姓名：' + this.deathName + ')，请家属与工作人员准备贡菜供饭仪式'
    this.defaultCeremonyTime = startDate
  },
  methods: {
    fetchNoticeUserOptions() {
      this.loadingNoticeUserOptions = true
      findFuneralHomeOptions({ destination_id: this.funeralHomeId }).then((res) => {
        if (res.code === 0) {
          this.noticeUserOptions = res.data
        }
        this.loadingNoticeUserOptions = false
      })
    },
    changeNotice(e) {
      this.noticeSend = !this.noticeSend
    },
    changeTask(e) {
      console.log(e)
      this.taskSend = !this.taskSend
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: 'tribute_ceremony', order_id: this.serviceOrderId, order_type: 'service' }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
        }
        this.loadingUserOptions = false
      })
    },
    handlePhoneNumberChange(id) {
      this.form.setFieldsValue({ phone_number: undefined })
      if (!id) {
        return
      }
      findUser(id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ phone_number: res.data.phone_number })
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const fieldsValue = this.form.getFieldsValue()
          const values = {
            ...fieldsValue,
            ceremony_time: formatDateTimeMin(fieldsValue.ceremony_time),
            notice: this.noticeSend,
            task: this.taskSend
          }
          if ((this.noticeSend === true || this.taskSend === true) && fieldsValue.ceremony_time === null) {
            this.$warning({
              title: '请选择仪式时间',
              content: ''
            })
            this.submitting = false
            return
          }
          prepareProvideFoodCeremony(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
