var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"准备贡菜供饭仪式"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 9 },"wrapper-col":{ span: 13 }},on:{"submit":_vm.handleSubmit}},[_c('span',{staticStyle:{"margin-left":"50px","margin-top":"50px"}},[_vm._v("请确认并维护以下信息。")]),_c('a-form-item',{attrs:{"label":"仪式时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ceremony_time', {
            initialValue: _vm.defaultCeremonyTime
          }]),expression:"['ceremony_time', {\n            initialValue: defaultCeremonyTime\n          }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' },"allow-clear":false}})],1),_c('a-form-item',{attrs:{"label":"准备贡菜供饭仪式通知"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['notice', {
            initialValue: 'checked',
            valuePropName: 'checked'
          }]),expression:"['notice', {\n            initialValue: 'checked',\n            valuePropName: 'checked'\n          }]"}],on:{"change":_vm.changeNotice}})],1),(_vm.noticeSend === true)?_c('a-form-item',{attrs:{"label":"提醒内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', {
            normalize: this.$lodash.trim,
            initialValue: _vm.defaultContent,
            rules: [
              { max: 100, message: '最多100个字符' },
              { required: true, message: '请输入提醒内容' }
            ]
          }]),expression:"['content', {\n            normalize: this.$lodash.trim,\n            initialValue: defaultContent,\n            rules: [\n              { max: 100, message: '最多100个字符' },\n              { required: true, message: '请输入提醒内容' }\n            ]\n          }]"}]})],1):_vm._e(),(_vm.noticeSend === true)?_c('a-form-item',{attrs:{"label":"发送目标"}},[_c('span',[_vm._v("小程序用户")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['notice_user_ids']),expression:"['notice_user_ids']"}],attrs:{"mode":"multiple","placeholder":"请选择工作人员","allow-clear":""},on:{"load":_vm.loadingNoticeUserOptions}},_vm._l((_vm.noticeUserOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"准备贡菜供饭仪式任务"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['task', {
            initialValue: 'checked',
            valuePropName: 'checked'
          }]),expression:"['task', {\n            initialValue: 'checked',\n            valuePropName: 'checked'\n          }]"}],on:{"change":_vm.changeTask}})],1),(_vm.taskSend === true)?_c('a-form-item',{attrs:{"label":"贡菜供饭仪式负责人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {
            initialValue: _vm.defaultUser.id > 0 ? _vm.defaultUser.id : undefined,
            rules: [
              { required: true, message: '请选择贡菜供饭仪式负责人' } ]
          }]),expression:"['user_id', {\n            initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,\n            rules: [\n              { required: true, message: '请选择贡菜供饭仪式负责人' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择贡菜供饭仪式负责人"},on:{"load":_vm.loadingUserOptions,"change":_vm.handlePhoneNumberChange}},_vm._l((_vm.userOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1):_vm._e(),(_vm.taskSend === true)?_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            initialValue: _vm.defaultUser.phone_number,
            normalize: this.$lodash.trim
          }]),expression:"['phone_number', {\n            initialValue: defaultUser.phone_number,\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }